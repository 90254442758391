import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
     authRequired: true,
    },
    component: () => import("../views/dashboard/index"),
  },

  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {

          store.dispatch("auth/logout");

        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      },
    },
    component: () => import("../views/auth/logout/basic")
  },

//Admin Dashboard
  {
    path: "/admin-dashboard",
    name: "admin-dashboard",
    meta: {
      title: "Admin Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/AdminDashboard"),
  },

  /*   Users     */
  {
    path: "/profile",
    name: "user-profile",
    meta: { title: "User Profile", authRequired: true },
    component: () => import("../views/profile/Index"),
  },
  {
    path: "/profile/results",
    name: "user-results",
    meta: { title: "User Results", authRequired: true },
    component: () => import("../views/profile/Results"),
  },




   /*   Users     */
  {
    path: "/user/list",
    name: "user-ist",
    meta: { title: "List", authRequired: true },
    component: () => import("../views/users/UsersLists"),
  },
  {
    path: "/user/detail/:id",
    name: "user-detail",
    meta: { title: "User Detail", authRequired: true },
    component: () => import("../views/users/UserDetail"),
  },
  {
    path: "/user/roles",
    name: "user-roles",
    meta: { title: "Roles", authRequired: true },
    component: () => import("../views/users/UserRoles"),
  },
  {
    path: "/user/permissions",
    name: "user-permissions",
    meta: { title: "Permissions", authRequired: true },
    component: () => import("../views/users/Permissions"),
  },




//Stations
  {
    path: "/stations",
    name: "stations-list",
    meta: { title: "Polling Stations", authRequired: true },
    component: () => import("../views/stations/StationList"),
  },

  {
    path: "/regions",
    name: "regions-list",
    meta: { title: "Regions", authRequired: true },
    component: () => import("../views/regions/Index"),
  },


  {
    path: "/constituencies",
    name: "constituency-list",
    meta: { title: "Constituencies", authRequired: true },
    component: () => import("../views/constituencies/Index"),
  },






  /*   Sms    */
  {
    path: "/sms/group",
    name: "sms-group",
    meta: { title: "List", authRequired: true },
    component: () => import("../views/sms/SmsGroup"),
  },

  {
    path: "/sms/group-contacts/:id",
    name: "sms-group-contacts",
    meta: { title: "List", authRequired: true },
    component: () => import("../views/sms/SmsGroupContacts"),
  },

  {
    path: "/sms/batch",
    name: "sms-batch",
    meta: { title: "Sms Batches", authRequired: true },
    component: () => import("../views/sms/SmsBatch"),
  },

  {
    path: "/sms/history",
    name: "sms-history",
    meta: { title: "Sms Histories", authRequired: true },
    component: () => import("../views/sms/SmsHistory"),
  },



  {
    path: "/results",
    name: "results-list",
    meta: { title: "Results", authRequired: true },
    component: () => import("../views/results/Index"),
  },

  {
    path: "/results-stats",
    name: "results-stat",
    meta: { title: "Results Stat", authRequired: true },
    component: () => import("../views/results/ResultStat"),
  },


  {
    path: "/region-stat/:id",
    name: "region-stat",
    meta: { title: "region-stat", authRequired: true },
    component: () => import("../views/results/RegionStat"),
  },
  {
    path: "/constituency-stat/:id",
    name: "constituency-stat",
    meta: { title: "constituency-stat", authRequired: true },
    component: () => import("../views/results/ConstituencyStat"),
  },
    ///////////////////////////////////
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    meta: {
      title: "Error 404",
    },
    component: () => import("../views/errors/404-cover"),
  }
];
