import ConstituencyService from "@/services/ConstituencyService";
import { getError } from "@/helpers/errors";


export const state = {
    loading: false,
    error: "",
};

export const mutations = {
    SET_LOADING(state, loading) {
        state.loading = loading;
    },

    SET_ERROR(state, error) {
        state.error = error;
    },
};

export const actions = {
    getConstituencies({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ConstituencyService.getConstituencies(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    getStationList({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ConstituencyService.getConstituencyList(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    addStation({ commit }, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ConstituencyService.addConstituency(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    editStation({ commit }, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ConstituencyService.editConstituency(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    exportConstituencies({ commit }) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ConstituencyService.exportConstituencies()
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },

    getConstituenciesByRegionId({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ConstituencyService.getConstituenciesByRegionId(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    commit("SET_ERROR", getError(err));
                    reject(err);
                })
                .finally(() => commit("SET_LOADING", false));
        });
    },
};
