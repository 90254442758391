import {ApiService} from "@/services/ApiService";


class UserService {
    getAuthenticatedUser() {
        return ApiService.get("/api/user");
    }

    getUserProfile() {
        return ApiService.get("/api/user/profile");
    }

    getUserDetails() {
        return ApiService.get("/api/auth-user");
    }

    getUserPermission() {
        return ApiService.get("/api/user/permissions");
    }

    updateProfile(payload) {
        return ApiService.put(`/api/update-profile/${payload.id}`, payload);
    }
    updateUserAvatar(payload) {
        let formData = new FormData()
        formData.append('avatar', payload.file)
        return ApiService.post(`/api/update-profile-avatar/${payload.id}`,formData);
    }
    //USERS
    getUsers(options) {
        return ApiService.get(
            `/api/users-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`,
        );
    }
    getUserList() {
        return ApiService.get(
            `/api/user-list`,
        );
    }

    getSingleUser(id) {
        return ApiService.get(`/api/users/${id}`);
    }

    addUser(payload) {
        return ApiService.post(
            '/api/users',
            payload
        )
    }
    editUser(payload) {
        return ApiService.post(
            '/api/users/'+payload.id,
            payload.data
        )
    }
    activateUser(payload) {
        return ApiService.put(
            '/api/user/activate/'+payload.id,
            payload.data
        )
    }
    deleteUser(id){
        return ApiService.delete(
            '/api/users/'+id,
        )
    }
    deleteBulkUsers(selected_ids){
        return ApiService.post(
            '/api/users/delete/bulk',
            {selected_ids:selected_ids}
        )
    }
    exportUsers(){
        return ApiService.get(
            '/api/users/export/excel',{
                responseType:'blob'
            }
        )
    }
    // USER ROLES
    getRoles(options) {
        return ApiService.get(
            `/api/roles-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`,
        );
    }
    getRoleList() {
        return ApiService.get(
            `/api/role-list`,
        );
    }
    addRole(payload) {
        return ApiService.post(
            '/api/roles',
            payload
        )
    }
    editRole(payload) {
        return ApiService.put(
            '/api/roles/'+payload.id,
            payload
        )
    }
    deleteRole(id){
        return ApiService.delete(
            '/api/roles/'+id,
        )
    }
    deleteBulkRoles(selected_ids){
        return ApiService.post(
            '/api/roles/delete/bulk',
            {selected_ids:selected_ids}
        )
    }
    exportRoles(){
        return ApiService.get(
            '/api/roles/export/excel',{
                responseType:'blob'
            }
        )
    }
    // PERMISSIONS
    getPermissions(options) {
        return ApiService.get(
            `/api/permissions-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`,
        );
    }
    addPermission(payload) {
        return ApiService.post(
            '/api/permissions',
            payload
        )
    }
    editPermission(payload) {
        return ApiService.put(
            '/api/permissions/'+payload.id,
            payload
        )
    }
    deletePermission(id){
        return ApiService.delete(
            '/api/permissions/'+id,
        )
    }
    deleteBulkPermissions(selected_ids){
        return ApiService.post(
            '/api/permissions/delete/bulk',
            {selected_ids:selected_ids}
        )
    }



    assignUserStation(payload) {
        return ApiService.post(
            '/api/user-station/store',
            payload
        )
    }



    getUserAssignedStations(id) {
        return ApiService.get(
            `/api/user-assigned-stations/` +id,
        );
    }

    getUserTransmittedResults(id) {
        return ApiService.get(
            `/api/user-transmitted-results/` +id,
        );
    }

}


export default new UserService();
