import RegionService from "@/services/RegionService";
import { getError } from "@/helpers/errors";

export const state = {
    loading: false,
    error: "",
};

export const mutations = {
    SET_LOADING(state, loading) {
        state.loading = loading;
    },

    SET_ERROR(state, error) {
        state.error = error;
    },
};

export const actions = {
    getRegions({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            RegionService.getRegions(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    getRegionList({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            RegionService.getRegionList(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    addRegion({ commit }, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            RegionService.addRegion(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    editRegion({ commit }, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            RegionService.editRegion(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    exportRegions({ commit }) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            RegionService.exportRegions()
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
};
