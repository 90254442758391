import { ApiService } from "./ApiService";

export default {

    //SMS Group
    getSmsGroups(options) {
        return ApiService.get(
            `/api/sms-groups-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`,
        );
    },
    getSmsGroupList() {
        return ApiService.get(
            `/api/sms-group-list`,
        );
    },
    addSmsGroup(payload) {
        return ApiService.post(
            '/api/sms-group/store',
            payload
        )
    },
    editSmsGroup(payload) {
        return ApiService.post(
            '/api/sms-groups/'+payload.id,
            payload.data
        )
    },
    deleteSmsGroup(id){
        return ApiService.delete(
            '/api/sms-groups/'+id,
        )
    },
    deleteBulkSmsGroups(selected_ids){
        return ApiService.post(
            '/api/sms-groups/delete/bulk',
            {selected_ids:selected_ids}
        )
    },
    exportSmsGroups(){
        return ApiService.get(
            '/api/sms-groups/export/excel',{
                responseType:'blob'
            }
        )
    },
    getSmsGroup(id) {
        return ApiService.get(`/api/sms-groups/${id}`);
    },



    getSmsGroupContacts(options) {
        return ApiService.get(
            `/api/sms-group-contacts-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`,
        );
    },
    addSmsGroupContact(payload) {
        return ApiService.post(
            '/api/sms-group-contact/store',
            payload
        )
    },
    deleteSmsGroupContact(id){
        return ApiService.delete(
            '/api/sms-group-contacts/'+id,
        )
    },
    deleteBulkSmsGroupContacts(selected_ids){
        return ApiService.post(
            '/api/sms-group-contacts/delete/bulk',
            {selected_ids:selected_ids}
        )
    },
    exportSmsGroupContacts(){
        return ApiService.get(
            '/api/sms-group-contacts/export/excel',{
                responseType:'blob'
            }
        )
    },
    getSmsGroupContact(id) {
        return ApiService.get(`/api/sms-groups/${id}`);
    },


    //sms batch

    getSmsBatches(options) {
        return ApiService.get(
            `/api/sms-batches-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`,
        );
    },
    getSmsBatch(id) {
        return ApiService.get(`/api/sms-batches/${id}`);
    },
    getSmsBatchList() {
        return ApiService.get(
            `/api/sms-batch-list`,
        );
    },


    editSmsBatch(payload) {
        return ApiService.post(
            '/api/sms-groups/'+payload.id,
            payload.data
        )
    },
    deleteSmsBatch(id){
        return ApiService.delete(
            '/api/sms-batches/'+id,
        )
    },
    deleteBulkSmsBatches(selected_ids){
        return ApiService.post(
            '/api/sms-batches/delete/bulk',
            {selected_ids:selected_ids}
        )
    },

    sendSmsBatch(payload) {
        return ApiService.post(
            '/api/sms-group/send',
            payload
        )
    },



    //SMS History
    getSmsHistories(options) {
        return ApiService.get(
            `/api/sms-histories-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`,
        );
    },
    getSmsHistoryList() {
        return ApiService.get(
            `/api/sms-history-list`,
        );
    },
    addSmsHistory(payload) {
        return ApiService.post(
            '/api/sms-history/store',
            payload
        )
    },
    editSmsHistory(payload) {
        return ApiService.post(
            '/api/sms-histories/'+payload.id,
            payload.data
        )
    },
    deleteSmsHistory(id){
        return ApiService.delete(
            '/api/sms-histories/'+id,
        )
    },
    deleteBulkSmsHistories(selected_ids){
        return ApiService.post(
            '/api/sms-histories/delete/bulk',
            {selected_ids:selected_ids}
        )
    },
    exportSmsHistories(){
        return ApiService.get(
            '/api/sms-histories/export/excel',{
                responseType:'blob'
            }
        )
    },
    getSmsHistory(id) {
        return ApiService.get(`/api/sms-histories/${id}`);
    },


};
