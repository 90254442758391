import { AuthService } from "@/services/AuthService";
// eslint-disable-next-line no-unused-vars
import router from "../../router";
import { getError } from "@/helpers/errors";

const user = localStorage.getItem("jwt")
export const state = {
    user: user ? {
            status: {
                loggeduser: true,
            },
            user,
            error: null,
            authenticated:true,
        }
        : {
            status: {},
            user: null,
            error: null,
            authenticated:false,
        },
}

export const mutations = {
    loginRequest(state, user) {
        state.status = {
            loggingIn: true,
        };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = {
            loggeduser: true,
        };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
}

export const getters = {
    error: (state) => state.error,
    loading: (state) => state.loading,
}
/* eslint-disable no-unused-vars */
export const actions = {
    registerUser({commit},payload) {
        return new Promise((resolve, reject) => {
            AuthService.registerUser(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    // login({ dispatch, commit }, payload) {
    //     commit("loginRequest", payload.email);
    //
    //     AuthService.login(payload).then(
    //         (user) => {
    //             console.log(user)
    //             commit("loginSuccess", user);
    //
    //             //MB - removed /dashboard - 28 July 22
    //          //   router.push("/");
    //         },
    //         (error) => {
    //             commit("loginFailure", error);
    //             //TODO - Notification
    //             // dispatch("notification/error", error, {
    //             //     root: true,
    //             // });
    //         }
    //     );
    // },


    login({ commit }, payload) {
        return AuthService.login(payload).then(
            (user) => {
                commit("loginSuccess", payload);
                return Promise.resolve(payload);
            },
            (error) => {
                commit("loginFailure");
                return Promise.reject(error);
            }
        );
    },


    logout({ commit }) {
        AuthService.logout();
        commit("logout");
    },

    getUser() {
        return new Promise((resolve, reject) => {
            AuthService.getUser()
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    resendPin({commit},payload) {
        return new Promise((resolve, reject) => {
            AuthService.resendPin(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    verifyEmail({commit},payload) {
        return new Promise((resolve, reject) => {
            AuthService.verifyEmail(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    forgotPassword({commit},payload) {
        return new Promise((resolve, reject) => {
            AuthService.forgotPassword(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    verifyPasswordPin({commit},payload) {
        return new Promise((resolve, reject) => {
            AuthService.verifyPasswordPin(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    resetPassword({commit},payload) {
        return new Promise((resolve, reject) => {
            AuthService.resetPassword(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },

}
