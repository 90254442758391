import UserService from "../../services/UserService";
import { getError } from "../../helpers/errors";

export const state = {
    loading: false,
    error: ''
}

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
}

export const actions = {
  // USER PROFILE
  getUserProfile({ commit },options) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.getUserProfile(options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  getUserDetails({ commit },options) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.getUserDetails(options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  updateProfile({ commit },options) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.updateProfile(options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  getUserPermission({ commit },options) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.getUserPermission(options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  // END USER PROFILE
  getUsers({ commit },options) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.getUsers(options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },

  getSingleUser({ commit }, options) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      UserService.getSingleUser(options)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            commit("SET_ERROR", getError(err));
            reject(err);
          })
          .finally(() => commit("SET_LOADING", false));
    });
  },
  getUserList({ commit },options) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.getUserList(options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  addUser({ commit },payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.addUser(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  editUser({ commit },payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.editUser(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  activateUser({ commit },payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.activateUser(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  deleteUser({ commit },id) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.deleteUser(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  deleteBulkUsers({ commit },id) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.deleteBulkUsers(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  exportUsers({ commit },id) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.exportUsers(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  // USER ROLES
  getRoles({ commit },options) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.getRoles(options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  getRoleList({ commit },options) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.getRoleList(options)
        .then(( {data }) => {
          console.log(data)
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  addRole({ commit },payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.addRole(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  editRole({ commit },payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.editRole(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  deleteBulkRoles({ commit },id) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.deleteBulkRoles(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  deleteRole({ commit },id) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.deleteRole(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  exportRoles({ commit },id) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.exportRoles(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  //
  getPermissions({ commit },id) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.getPermissions(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  addPermission({ commit },id) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.addPermission(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  editPermission({ commit },id) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.editPermission(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  deletePermission({ commit },id) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.deletePermission(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  deleteBulkPermissions({ commit },id) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.deleteBulkPermissions(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },




  //stations
  getUserAssignedStations({ commit }, options) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      UserService.getUserAssignedStations(options)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            commit("SET_ERROR", getError(err));
            reject(err);
          })
          .finally(() => commit("SET_LOADING", false));
    });
  },

  getUserTransmittedResults({ commit }, options) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      UserService.getUserTransmittedResults(options)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            commit("SET_ERROR", getError(err));
            reject(err);
          })
          .finally(() => commit("SET_LOADING", false));
    });
  },
  assignUserStation({ commit },payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      UserService.assignUserStation(payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(getError(err));
          });
    });
  },

}
