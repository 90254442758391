import { ApiService } from "@/services/ApiService";

export default {
    getRegions(options) {
        return ApiService.get(
            `/api/regions-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`
        );
    },
    getRegionList() {
        return ApiService.get(
            `/api/region-list`
        );
    },
    addRegion(payload) {
        return ApiService.post("/api/regions", payload);
    },
    editRegion(payload) {
        return ApiService.put("/api/regions/" + payload.id, payload);
    },

    exportRegions() {
        return ApiService.get("/api/regions/export/excel", {
            responseType: "blob",
        });
    },


    getRegion(id) {
        return ApiService.get(`/api/regions/${id}`);
    },


};
