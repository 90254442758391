import { ApiService } from "./ApiService";

export default {
    // USER PROFILE
    getUserProfile() {
        return ApiService.get("/api/user");
    },

    getUserDetails() {
        return ApiService.get("/api/auth-user");
    },

    getUserPermission() {
        return ApiService.get("/api/user/permissions");
    },

    updateProfile(id) {
        return ApiService.get(`/api/update-profile/${id}`);
    },

    getUserAssignedStations() {


        return ApiService.get(`/api/user/assigned-stations`);
    },

    getUserTransmittedResults() {
        return ApiService.get(`/api/user/transmitted-results-list`);
    },


    getUserList() {
        return ApiService.get(
            `/api/user-list`,
        );
    },
    addPresidentResult(payload) {
        return ApiService.post(
            '/api/user/add-president-result',
            payload
        )
    },

    editPresidentResult(payload) {
        return ApiService.post(
            'api/user/update-president-result/'+payload.id,
            payload.data
        )
    },


    addParliamentResult(payload) {
        return ApiService.post(
            'api/user/add-parliament-result',
            payload
        )
    },

    editParliamentResult(payload) {
        return ApiService.post(
            'api/user/update-parliament-result/'+payload.id,
            payload.data
        )
    },



    editUser(payload) {
        return ApiService.post(
            '/api/users/'+payload.id,
            payload.data
        )
    },
    activateUser(payload) {
        return ApiService.put(
            '/api/user/activate/'+payload.id,
            payload.data
        )
    },
    deleteUser(id){
        return ApiService.delete(
            '/api/users/'+id,
        )
    },
    deleteBulkUsers(selected_ids){
        return ApiService.post(
            '/api/users/delete/bulk',
            {selected_ids:selected_ids}
        )
    },



};
