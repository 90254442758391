import { ApiService } from "@/services/ApiService";

export default {
    getConstituencies(options) {
        return ApiService.get(
            `/api/constituencies-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`
        );
    },
    getConstituencyList() {
        return ApiService.get(
            `/api/constituency-list`
        );
    },
    addConstituency(payload) {
        return ApiService.post("/api/constituencies", payload);
    },
    editConstituency(payload) {
        return ApiService.put("/api/constituencies/" + payload.id, payload);
    },

    exportConstituencies() {
        return ApiService.get("/api/constituencies/export/excel", {
            responseType: "blob",
        });
    },

    getConstituenciesByRegionId(id) {
        return ApiService.get(`/api/get-constituencies-regionid/${id}`);
    },

};
