import ClientService from "@/services/ClientService";
import { getError } from "@/helpers/errors";

export const state = {
  loading: false,
  error: "",
};

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  getClients({ commit }, options) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      ClientService.getClients(options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  getClientList({ commit }, options) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      ClientService.getClientList(options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  addClient({ commit }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      ClientService.addClient(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  editClient({ commit }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      ClientService.editClient(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  deleteClient({ commit }, id) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      ClientService.deleteClient(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },

  deleteMultipleClinets({ commit }, clients_ids) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      ClientService.deleteMutipleClients(clients_ids)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },

  exportClientExcel({ commit }) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      ClientService.exportClientsExcel()
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
};
