import axios from "axios";

//const apiUrl = import.meta.env.VITE_APP_API_URL;

export const ApiService = axios.create({

     baseURL: "http://localhost",
    //   baseURL: "/",
    headers: {
        "X-Requested-With": "XMLHttpRequest",
    },
    withCredentials: true, // required to handle the CSRF token
});

// interceptors to add token
ApiService.interceptors.request.use(
    (config) => {
        //const token = TokenService.getLocalAccessToken();
        //   if (token) {
        config.headers["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("user"));
        //  }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/*
 * Add a response interceptor
 */
ApiService.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && [401, 419].includes(error.response.status)) {
            console.error("User not authorized, login failed with API");
        }
        return Promise.reject(error);
    }
);
