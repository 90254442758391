import { getError } from "@/helpers/errors";
import ProfileService from "@/services/ProfileService";

export const state = {
    loading: false,
    error: ''
}

export const mutations = {
    SET_LOADING(state, loading) {
        state.loading = loading;
    },

    SET_ERROR(state, error) {
        state.error = error;
    },
}

export const actions = {
    // USER PROFILE
    getUserProfile({ commit },options) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.getUserProfile(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    getUserDetails({ commit },options) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.getUserDetails(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    updateProfile({ commit },options) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.updateProfile(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    getUserPermission({ commit },options) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.getUserPermission(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    // END USER PROFILE
    getUsers({ commit },options) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.getUsers(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },

    getSingleUser({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ProfileService.getSingleUser(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    commit("SET_ERROR", getError(err));
                    reject(err);
                })
                .finally(() => commit("SET_LOADING", false));
        });
    },
    getUserList({ commit },options) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.getUserList(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    addUser({ commit },payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.addUser(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    editUser({ commit },payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.editUser(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    activateUser({ commit },payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.activateUser(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    deleteUser({ commit },id) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.deleteUser(id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    deleteBulkUsers({ commit },id) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.deleteBulkUsers(id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    exportUsers({ commit },id) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.exportUsers(id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    // USER ROLES
    getRoles({ commit },options) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.getRoles(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    getRoleList({ commit },options) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.getRoleList(options)
                .then(( {data }) => {
                    console.log(data)
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    addRole({ commit },payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.addRole(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    editRole({ commit },payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.editRole(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },



    //stations
    getUserAssignedStations({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ProfileService.getUserAssignedStations(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    commit("SET_ERROR", getError(err));
                    reject(err);
                })
                .finally(() => commit("SET_LOADING", false));
        });
    },

    getUserTransmittedResults({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ProfileService.getUserTransmittedResults(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    commit("SET_ERROR", getError(err));
                    reject(err);
                })
                .finally(() => commit("SET_LOADING", false));
        });
    },


    addPresidentResult({ commit },payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.addPresidentResult(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },

    editPresidentResult({ commit },payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.editPresidentResult(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },



    addParliamentResult({ commit },payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.addParliamentResult(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },

    editParliamentResult({ commit },payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ProfileService.editParliamentResult(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },


}
