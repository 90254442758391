import StationService from "@/services/StationService";
import { getError } from "@/helpers/errors";


export const state = {
    loading: false,
    error: "",
};

export const mutations = {
    SET_LOADING(state, loading) {
        state.loading = loading;
    },

    SET_ERROR(state, error) {
        state.error = error;
    },
};

export const actions = {
    getStations({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            StationService.getStations(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    getStationList({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            StationService.getStationList(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    addStation({ commit }, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            StationService.addStation(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    editStation({ commit }, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            StationService.editStation(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    exportStations({ commit }) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            StationService.exportStations()
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },

    getStationsByConstituencyId({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
           StationService.getStationsByConstituencyId(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    commit("SET_ERROR", getError(err));
                    reject(err);
                })
                .finally(() => commit("SET_LOADING", false));
        });
    },
};
