import { ApiService } from "@/services/ApiService";

export default {
    getResults(options) {
        return ApiService.get(
            `/api/result-transmissions-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`
        );
    },
    getResultList() {
        return ApiService.get(
            `/api/result-transmission-list`
        );
    },
    addResult(payload) {
        return ApiService.post("/api/result-transmissions", payload);
    },
    editResult(payload) {
        return ApiService.put("/api/result-transmissions/" + payload.id, payload);
    },

    exportResults() {
        return ApiService.get("/api/result-transmissions/export/excel", {
            responseType: "blob",
        });
    },


    //stats
    getNationalStatsSummary() {
        return ApiService.get(
            `/api/tr-stats-national`
        );
    },

    getNationalStats() {
        return ApiService.get(
            `/api/tr-stats-all-regions`
        );
    },
    //
    // getRegionalStats() {
    //     return ApiService.get(`/api/tr-stats-region`);
    // },
    //
    //
    //
    //
    //
    // getRegionalSummaryStats(id) {
    //     return ApiService.get(`/api/tr-stats-region-summary?region_id=${id}`);
    // },



    getRegionStats(id) {
        return ApiService.get(`/api/tr-stats-region?region_id=${id}`);
    },
    getRegionStatsSummary(id) {
        return ApiService.get(`/api/tr-stats-region-summary?region_id=${id}`);
    },



    getConstituencyStats(id) {
        return ApiService.get(`/api/tr-stats-constituency?constituency_id=${id}`);
    },
    getConstituencyStatsSummary(id) {
        return ApiService.get(`/api/tr-stats-constituency-summary?constituency_id=${id}`);
    },




    getStationStats(id) {
        return ApiService.get(`/api/tr-stats-constituency?constituency_id=${id}`);
    },
    getStationStatsSummary(id) {
        return ApiService.get(`/api/tr-stats-constituency-summary?constituency_id=${id}`);
    },



    editPresidentResult(payload) {
        return ApiService.post(
            'api/admin/update-president-result/'+payload.id,
            payload.data
        )
    },

    editParliamentResult(payload) {
        return ApiService.post(
            'api/admin/update-parliament-result/'+payload.id,
            payload.data
        )
    },


};
