import { ApiService } from "@/services/ApiService";

export default {
    getStations(options) {
        return ApiService.get(
            `/api/stations-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`
        );
    },
    getStationList() {
        return ApiService.get(
            `/api/station-list`
        );
    },
    addStation(payload) {
        return ApiService.post("/api/stations", payload);
    },
    editStation(payload) {
        console.log(payload.data)
        return ApiService.post("/api/update-station/" + payload.id, payload.data);
    },

    exportStations() {
        return ApiService.get("/api/stations/export/excel", {
            responseType: "blob",
        });
    },


    getStationsByConstituencyId(id) {
        return ApiService.get(`/api/get-stations-constituencyid/${id}`);
    },
};
