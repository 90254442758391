import { ApiService } from "@/services/ApiService";
import router from "../router";
import store from "@/state/store";
function registerUser(payload){
    return ApiService.post("/api/custom-auth/register", payload);
}

 async function login(payload) {
  await   ApiService.post("api/custom-auth/login", payload).then((response) => {
        //TODO: Encrypt Data
        if (response.data) {
            localStorage.setItem("user", JSON.stringify(response.data.token));
        }
        return response.data;
    });

   // await  initAuthUser();

    store.dispatch("user/getAuthenticatedUser");
}


// async function initAuthUser() {
//     await store.dispatch("user/getAuthenticatedUser");
// }
async function logout() {
    await ApiService.post("/api/custom-auth/logout");
    // remove user from local storage to log user out
    localStorage.removeItem("user");
    //TODO: check if not redundant
    localStorage.clear();
    // Fire a refresh
    //TODO: check if this is redundant
    window.location.reload();
    router.push({
        path: "/",
    });
}

function getUser() {
    return ApiService.get("/api/user");
}
function resendPin(payload) {
    return ApiService.post("/api/custom-auth/resend/email/token", payload);
}
function verifyEmail(payload) {
    return ApiService.post("/api/custom-auth/email/verify", payload);
}
function forgotPassword(payload) {
    return ApiService.post('/api/custom-auth/forgot-password', payload)
}
function verifyPasswordPin(payload) {
    return ApiService.post('/api/custom-auth/verify/pin', payload)
}
function resetPassword(payload) {
    return ApiService.post('/api/custom-auth/reset-password', payload)
}
export const AuthService = {
    login,
    logout,
    getUser,
    registerUser,
    resendPin,
    verifyEmail,
    forgotPassword,
    verifyPasswordPin,
    resetPassword
};
