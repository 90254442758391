<template>
  <vue3-progress />
 <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  mounted() {
    this.$progress.finish();
  },
  created() {
    this.$progress.start();

    this.$router.beforeEach((to, from, next) => {
      this.$progress.start();
      next();
    });

    // eslint-disable-next-line no-unused-vars
    this.$router.afterEach((to, from) => {
      this.$progress.finish();
    });
  },
}
</script>


