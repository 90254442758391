import SmsService from "@/services/SmsService";
import { getError } from "@/helpers/errors";

export const state = {
    loading: false,
    error: ''
}

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
}

export const actions = {
  //SMS Groups
  getSmsGroups({ commit },options) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      SmsService.getSmsGroups(options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
    getSmsGroup({ commit },options) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            SmsService.getSmsGroup(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
  getSmsGroupList({ commit },options) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      SmsService.getSmsGroupList(options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  addSmsGroup({ commit },payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      SmsService.addSmsGroup(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  editSmsGroup({ commit },payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      SmsService.editSmsGroup(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  deleteSmsGroup({ commit },id) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      SmsService.deleteSmsGroup(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  deleteBulkSmsGroups({ commit },id) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      SmsService.deleteBulkSmsGroups(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  exportSmsGroups({ commit },id) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      SmsService.exportSmsGroups(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },



  getSmsGroupContacts({ commit },options) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      SmsService.getSmsGroupContacts(options)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(getError(err));
          });
    });
  },
  getSmsGroupContactList({ commit },options) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      SmsService.getSmsGroupContactList(options)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(getError(err));
          });
    });
  },
  addSmsGroupContact({ commit },payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      SmsService.addSmsGroupContact(payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(getError(err));
          });
    });
  },
  editSmsGroupContact({ commit },payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      SmsService.editSmsGroupContact(payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(getError(err));
          });
    });
  },
  deleteSmsGroupContact({ commit },id) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      SmsService.deleteSmsGroupContact(id)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(getError(err));
          });
    });
  },
  deleteBulkSmsGroupContacts({ commit },id) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      SmsService.deleteBulkSmsGroupContacts(id)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(getError(err));
          });
    });
  },
  exportSmsGroupContacts({ commit },id) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      SmsService.exportSmsGroupContacts(id)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(getError(err));
          });
    });
  },



    //SMS batch
    getSmsBatches({ commit },options) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            SmsService.getSmsBatches(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    getSmsBatch({ commit },options) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            SmsService.getSmsBatch(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    getSmsBatchList({ commit },options) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            SmsService.getSmsBatchList(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    sendSmsBatch({ commit },payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            SmsService.sendSmsBatch(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    editSmsBatch({ commit },payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            SmsService.editSmsBatch(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    deleteSmsBatch({ commit },id) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            SmsService.deleteSmsBatch(id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    deleteBulkSmsBatches({ commit },id) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            SmsService.deleteBulkSmsBatches(id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },




    //SMS History
    getSmsHistories({ commit },options) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            SmsService.getSmsHistories(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    getSmsHistory({ commit },options) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            SmsService.getSmsHistory(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    getSmsHistoryList({ commit },options) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            SmsService.getSmsHistoryList(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    addSmsHistory({ commit },payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            SmsService.addSmsHistory(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    editSmsHistory({ commit },payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            SmsService.editSmsHistory(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    deleteSmsHistory({ commit },id) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            SmsService.deleteSmsHistory(id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    deleteBulkSmsHistories({ commit },id) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            SmsService.deleteBulkSmsHistories(id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    exportSmsHistories({ commit },id) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            SmsService.exportSmsHistories(id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },


}
