import ResultService from "@/services/ResultService";
import { getError } from "@/helpers/errors";


export const state = {
    loading: false,
    error: "",
};

export const mutations = {
    SET_LOADING(state, loading) {
        state.loading = loading;
    },

    SET_ERROR(state, error) {
        state.error = error;
    },
};

export const actions = {
    getResults({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ResultService.getResults(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    getResultList({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ResultService.getResultList(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    addResult({ commit }, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ResultService.addResult(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    editResult({ commit }, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ResultService.editResult(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    exportResults({ commit }) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ResultService.exportResults()
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },

    //stats
    getNationalStatsSummary({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ResultService.getNationalStatsSummary(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    getNationalStats({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ResultService.getNationalStats(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },


    getRegionStats({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ResultService.getRegionStats(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    getRegionStatsSummary({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ResultService.getRegionStatsSummary(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },

    getConstituencyStats({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ResultService.getConstituencyStats(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    getConstituencyStatsSummary({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ResultService.getConstituencyStatsSummary(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },


    getStationStats({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ResultService.getStationStats(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },
    getStationStatsSummary({ commit }, options) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ResultService.getStationStatsSummary(options)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },


    editPresidentResult({ commit },payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ResultService.editPresidentResult(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },

    editParliamentResult({ commit },payload) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            ResultService.editParliamentResult(payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(getError(err));
                });
        });
    },

};
