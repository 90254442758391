// import { getError } from "@/helpers/errors";
// import UserService from "@/services/UserService";
//
// export const user = {
//     namespaced: true,
//
//     state: {
//         loading: false,
//         error: null,
//         authenticatedUser: {},
//         users: [],
//         user: {},
//     },
//
//     actions: {
//         getAuthenticatedUser({ commit }) {
//             return new Promise((resolve, reject) => {
//                 commit("SET_LOADING", true);
//                 UserService.getAuthenticatedUser()
//                     .then(({ data }) => {
//                         commit("SET_AUTHENTICATED_USER", data);
//
//                         // save customer type id
//                      //   localStorage.setItem("customer_type_id", data.customer.id);
//
//                         resolve(data);
//                     })
//                     .catch((err) => {
//                         //TODO: if error remove user from localstorage
//                         // localStorage.removeItem("user");
//                         //sign out
//                         commit("SET_ERROR", getError(err));
//                         reject(err);
//                     })
//                     .finally(() => {
//                         commit("SET_LOADING", false);
//                     });
//             });
//         },
//
//
//     },
//
//     mutations: {
//         SET_LOADING(state, loading) {
//             state.loading = loading;
//         },
//         SET_ERROR(state, error) {
//             state.error = error;
//         },
//         SET_AUTHENTICATED_USER(state, data) {
//             state.authenticatedUser = data;
//         },
//
//     },
//
//     getters: {
//         error: (state) => state.error,
//         loading: (state) => state.loading,
//         authenticatedUser: (state) => state.authenticatedUser,
//         users: (state) => state.users,
//         user: (state) => state.user,
//     },
// };




import {getError} from "@/helpers/errors";
import UserService from "@/services/UserService";

export const namespaced = true;

export const state = {
    loading: false,
    error: null,
    authUser: [],
};

export const actions = {
    getAuthenticatedUser({ commit }) {
        return new Promise((resolve, reject) => {
            commit("SET_LOADING", true);
            UserService.getAuthenticatedUser()
                .then((response) => {
                    commit("SET_AUTH_USER", response.data);
                    localStorage.setItem("authUser", JSON.stringify(response.data));
                    resolve(response);
                })
                .catch((err) => {
                    //TODO: tofix
                    localStorage.removeItem("user");
                    //TODO: tofix

                    commit("SET_ERROR", getError(err));
                    reject(err);
                })
                .finally(() => {
                    commit("SET_LOADING", false);
                });
        });
    },

};

export const mutations = {
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_AUTH_USER(state, data) {
        state.authUser = data;
    },
};

export const getters = {
    error: (state) => state.error,
    loading: (state) => state.loading,
    authUser: (state) => state.authUser,
};
